import React, { useState } from 'react';
import axios from 'axios';

interface BatchDataManipulatorProps {
    data: any[];
    batchName: string | null;  // Ensure batchName is passed as string or null
}

const BatchDataManipulator: React.FC<BatchDataManipulatorProps> = ({ data, batchName }) => {
    const [isOpen, setIsOpen] = useState(false); // To toggle dropdown visibility
    const [filterOption, setFilterOption] = useState<string>('all');

    
    // Toggle dropdown visibility
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Handle filtering logic
    const handleFilter = async (filter: string) => {
        setFilterOption(filter);
        setIsOpen(false); // Close dropdown after selection

        let filteredData: any[] = [];
        switch (filter) {
            case 'Ja':
                filteredData = data.filter(item => item.status === 'Ja');
                break;
            case 'Nein':
                filteredData = data.filter(item => item.status === 'Nein');
                break;
            case 'WVL':
                filteredData = data.filter(item => item.status === 'WVL');
                break;
            case 'EKV':
                filteredData = data.filter(item => item.status === 'EKV');
                break;
            case 'Nicht bearbeitet':
                filteredData = data.filter(item => item.status !== 'Nicht bearbeitet');
                break;
            default:
                filteredData = data; // Show all data
                break;
        }

        // Send filtered data to backend to generate Excel
        try {
            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');
    
            // Make the POST request to your Django endpoint with token authentication
            const response = await axios.post(
                'https://toolex.herweck.de/api/export-excel/', // Your Django endpoint
                { data: filteredData, batchName: batchName || 'exported_data' }, // Fall back to 'exported_data' if batchName is null
                {
                    responseType: 'blob', // Important to handle the file response
                    headers: {
                        Authorization: `Token ${token}`,  // Include the token in the Authorization header
                    },
                }
            );
    
            // Create a link to download the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${batchName || 'exported_data'}.xlsx`); // Use batchName in filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    return (
        <div className="relative inline-block text-left">
            {/* Export Button */}
            <button
                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={toggleDropdown}
            >
                Export
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('all')}>
                            All
                        </button>
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('Ja')}>
                            Ja
                        </button>
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('Nein')}>
                            Nein
                        </button>
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('WVL')}>
                            WVL
                        </button>
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('EKV')}>
                            EKV
                        </button>
                        <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left" onClick={() => handleFilter('Nicht bearbeitet')}>
                            All but Nicht bearbeitet
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BatchDataManipulator;
