import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BatchSelector from './BatchSelector';
import BatchTable from './BatchTable';
import BatchDataManipulator from './BatchDataManipulator';
import ExcelUploader from './ExcelUploader';
import ScoreComponent from './ScoreComponent';
import BatchSummaryComponent from './BatchSummaryComponent';
import UserProfile from './UserProfile'; // Import UserProfile component
import Header from './Header';

const ParentComponent: React.FC = () => {
    const [selectedBatch, setSelectedBatch] = useState<string | null>(null);
    const [batchData, setBatchData] = useState<any[]>([]);
    const [batchNames, setBatchNames] = useState<string[]>([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true); // Sidebar open by default
    const [isScoreOpen, setIsScoreOpen] = useState<boolean>(false); // Score overlay state

    useEffect(() => {
        const token = localStorage.getItem('token');

        axios.get('https://toolex.herweck.de/api/product-important-features/', {
            headers: {
                Authorization: `Token ${token}`,
            }
        })
        .then((response) => {
            const fetchedBatchNames: string[] = [];
            const fetchedBatchData: any[] = [];

            response.data.forEach((batch: any) => {
                fetchedBatchNames.push(batch.batch_name);
                fetchedBatchData.push(batch.products);
            });

            setBatchNames(fetchedBatchNames);
            setBatchData(fetchedBatchData);
        })
        .catch((error) => {
            console.error('Error fetching data: ', error);
        });
    }, []);

    useEffect(() => {
        if (selectedBatch) {
            setIsSidebarOpen(false);
        }
    }, [selectedBatch]);

    const selectedBatchIndex = selectedBatch ? batchNames.indexOf(selectedBatch) : -1;

    const handleScoreSubmit = () => {
        setIsScoreOpen(false);
    };

    return (
<div>
    <Header />

    <div className="relative flex">
        {/* Sidebar */}
        <div
            className={`fixed top-0 left-0 h-full bg-gray-100 shadow-lg transition-transform duration-300 ease-in-out 
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 p-4 pt-16 space-y-4`}
        >
            {/* Sidebar Content */}
            <BatchSelector 
                batches={batchNames} 
                onSelectBatch={(batch) => setSelectedBatch(batch)} 
            />
            <BatchSummaryComponent />
            <BatchDataManipulator data={batchData[selectedBatchIndex]} batchName={selectedBatch} />
            <ExcelUploader />
            <button
                className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                onClick={() => setIsScoreOpen(true)}
            >
                Open Score Settings
            </button>
        </div>

        {/* Toggle Button - Hamburger menu or close icon */}
        <button 
            className="fixed top-4 left-4 bg-gray-300 text-gray-700 p-2 rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-12 h-12 z-50"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
            {isSidebarOpen ? (
                <span className="text-xl">✖</span>
            ) : (
                <div className="space-y-1">
                    <span className="block w-6 h-0.5 bg-gray-700"></span>
                    <span className="block w-6 h-0.5 bg-gray-700"></span>
                    <span className="block w-6 h-0.5 bg-gray-700"></span>
                </div>
            )}
        </button>

        {/* Main Content */}
        <div
            className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-8`}
        >
            {selectedBatch && selectedBatchIndex >= 0 && (
                <BatchTable data={batchData[selectedBatchIndex]} batchName={selectedBatch} />
            )}
        </div>
    </div>

    {/* ScoreComponent Modal */}
    {isScoreOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                <h2 className="text-2xl font-bold mb-4">Score Settings</h2>
                <ScoreComponent onSubmit={handleScoreSubmit} />
                <button
                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                    onClick={() => setIsScoreOpen(false)}
                >
                    Close
                </button>
            </div>
        </div>
    )}
</div>


    );
}

export default ParentComponent;
