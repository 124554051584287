import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Product {
    EAN: string;
    status: string;
    manufacturer: string;
    color: string;
    Best_Margin_Idealo_Percentage: number | null;
    Online_Since: string;
    Avg_Monthly_Sold: number | null;
    Avg_Review: number | null;
    Review_Count: number;
    Sub_Category_Name: string;
    Latest_Sales_Rank_Sub_Category: number;
    Total_Offers_Idealo: number;
    volume_category: string;
    Current_Amazon_Margin_EUR: number;
    classifications: {
        volume: string;
        color: string;
        amazon_margin: string;
        idealo_margin: string;
        online_since: string;
        avg_monthly_sold: string;
        avg_review: string;
        review_count: string;
        sub_category_rank: string;
        total_offers_idealo: string;
    };
    score: number;
}

interface BatchTableProps {
    data: Product[];
    batchName: string;
}

const colorMap = {
    good: 'bg-green-500 text-white',
    medium: 'bg-yellow-500 text-black',
    bad: 'bg-red-500 text-white',
    Unknown: 'bg-gray-300 text-gray-900', // Fallback color for unknown or undefined classifications
};

const statusColorMap = {
    'Nicht bearbeitet': 'bg-gray-300 text-gray-900',
    'Ja': 'bg-green-500 text-white',
    'Nein': 'bg-red-500 text-white',
    'WVL': 'bg-yellow-500 text-black',
    'EKV': 'bg-purple-500 text-white',
};

const getColorClass = (classification: string) => {
    return colorMap[classification as keyof typeof colorMap] || colorMap['Unknown'];
};

const getStatusClass = (status: string) => {
    return statusColorMap[status as keyof typeof statusColorMap] || statusColorMap['Nicht bearbeitet'];
};

const BatchTable: React.FC<BatchTableProps> = ({ data,batchName }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [isOpen, setIsOpen] = useState(Array(data.length).fill(false)); // Manage dropdown visibility per row
    console.log('Batch Table Data',batchName);
    useEffect(() => {
        // Sort products by score before rendering
        const sortedProducts = [...data].sort((a, b) => b.score - a.score);
        setProducts(sortedProducts);
    }, [data]);

    const handleStatusChange = async (index: number, newStatus: string) => {
        const updatedProducts = [...products];
        const productEAN = updatedProducts[index].EAN;
    
        // Update local state
        updatedProducts[index].status = newStatus;
        setProducts(updatedProducts);
    
        // Close dropdown after selecting an option
        const updatedOpen = [...isOpen];
        updatedOpen[index] = false;
        setIsOpen(updatedOpen);
    
        console.log('New status: ', newStatus, 'Product EAN: ', productEAN, 'Batch Name:', batchName);
    
        try {
            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');
        
            // Make the API call to update the status on the backend
            const response = await fetch('https://toolex.herweck.de/api/update-product-offer-status/', {
                method: 'PUT', // Change to PUT based on the backend implementation
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`, // Include the token in the Authorization header
                },
                body: JSON.stringify({
                    EAN: productEAN,
                    status: newStatus,
                    batch_name: batchName, // Pass the batch name from props
                }),
            });
        
            if (!response.ok) {
                // Handle error response
                const errorData = await response.json();
                console.error('Error updating product status:', errorData);
            } else {
                // Handle successful response
                const successData = await response.json();
                console.log('Product status updated successfully:', successData);
            }
        } catch (error) {
            console.error('Error while sending request:', error);
        }        
    };
    
    

    const toggleDropdown = (index: number) => {
        const updatedOpen = [...isOpen];
        updatedOpen[index] = !updatedOpen[index];
        setIsOpen(updatedOpen);
    };

    return (
        <div className="overflow-x-auto max-h-[1000px]">
            <table className="min-w-full text-xs text-gray-700 border-collapse">
                <thead className="bg-gray-900 text-white sticky top-0 z-50">
                    <tr>
                        <th className="px-2 py-1 border-b border-gray-600">EAN</th>
                        <th className="px-2 py-1 border-b border-gray-600">Status</th>
                        <th className="px-2 py-1 border-b border-gray-600">Manufacturer</th>
                        <th className="px-2 py-1 border-b border-gray-600">Color</th>
                        <th className="px-2 py-1 border-b border-gray-600">Volume Category</th>
                        <th className="px-2 py-1 border-b border-gray-600">RE pro Stück</th>
                        <th className="px-2 py-1 border-b border-gray-600">Best Margin Idealo (%)</th>
                        <th className="px-2 py-1 border-b border-gray-600">Online Since</th>
                        <th className="px-2 py-1 border-b border-gray-600">Avg Monthly Sold</th>
                        <th className="px-2 py-1 border-b border-gray-600">Avg Review</th>
                        <th className="px-2 py-1 border-b border-gray-600">Review Count</th>
                        <th className="px-2 py-1 border-b border-gray-600">Sub Category</th>
                        <th className="px-2 py-1 border-b border-gray-600">Latest Sales Rank (Sub Category)</th>
                        <th className="px-2 py-1 border-b border-gray-600">Total Offers (Idealo)</th>
                        <th className="px-2 py-1 border-b border-gray-600">Score</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                    {products.map((product, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                            <td className="px-2 py-1 border-b border-gray-200">{product.EAN}</td>
                            <td className="px-2 py-1 border-b border-gray-200">
                                <div className="relative inline-block">
                                    <button
                                        onClick={() => toggleDropdown(index)}
                                        className={`px-2 py-1 rounded ${getStatusClass(product.status)}`}
                                    >
                                        {product.status}
                                    </button>
                                    {isOpen[index] && (
                                        <div className="absolute mt-1 bg-white shadow-md rounded-md z-50 w-32">
                                            {['Nicht bearbeitet', 'Ja', 'Nein', 'WVL', 'EKV'].map((option) => (
                                                <button
                                                    key={option}
                                                    className={`block w-full text-left px-2 py-1 hover:bg-gray-200 ${getStatusClass(option)}`}
                                                    onClick={() => handleStatusChange(index, option)}
                                                >
                                                    {option}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td className="px-2 py-1 border-b border-gray-200">{product.manufacturer}</td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.color)}`}>
                                {product.color}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.volume)}`}>
                                {product.volume_category}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.amazon_margin)}`}>
                                {product.Current_Amazon_Margin_EUR.toFixed(2)}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.idealo_margin)}`}>
                                {product.Best_Margin_Idealo_Percentage !== null
                                    ? product.Best_Margin_Idealo_Percentage.toFixed(2)
                                    : 'N/A'}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.online_since)}`}>
                                {new Date(product.Online_Since).toLocaleDateString()}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.avg_monthly_sold)}`}>
                                {product.Avg_Monthly_Sold !== null ? product.Avg_Monthly_Sold : 'N/A'}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.avg_review)}`}>
                                {product.Avg_Review !== null ? product.Avg_Review.toFixed(2) : 'N/A'}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.review_count)}`}>
                                {product.Review_Count}
                            </td>
                            <td className="px-2 py-1 border-b border-gray-200">{product.Sub_Category_Name}</td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.sub_category_rank)}`}>
                                {product.Latest_Sales_Rank_Sub_Category}
                            </td>
                            <td className={`px-2 py-1 border-b border-gray-200 ${getColorClass(product.classifications.total_offers_idealo)}`}>
                                {product.Total_Offers_Idealo}
                            </td>
                            <td className="px-2 py-1 border-b border-gray-200">{product.score.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BatchTable;
