import React from 'react';

interface BatchSelectorProps {
    batches: string[];
    onSelectBatch: (batch: string) => void;
}

const BatchSelector: React.FC<BatchSelectorProps> = ({ batches, onSelectBatch }) => {
    return (
        <div className="flex justify-center my-4">
            <select
                className="w-full max-w-md p-3 border-2 border-gray-300 rounded-lg bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer"
                onChange={(e) => onSelectBatch(e.target.value)}
            >
                <option value="">Select a Batch</option>
                {batches.map((batch) => (
                    <option key={batch} value={batch}>
                        {batch}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default BatchSelector;
